import React, { useEffect } from "react";
import "./App.css";
import Header from "../src/components/headerComp/header";
import RealityComp from "./components/realityComp/realityComp";
import Dao from "./components/dao/dao";
import MarketPlaceComp from "./components/marketPlaceComp/marketPlaceComp";
import Footer from "./components/footerComp/footer";

function MainComp() {
  
  return (
    
        <div className="App">
          <Header />
          <RealityComp />
          <Dao />
          <MarketPlaceComp />
          <Footer />
        </div>
     
     
  );
}

export default MainComp;