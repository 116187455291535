import { useState } from "react";
import "./dao.css";
import daoParrot from "../../images/dao/point_4.png";
import daoImgRotate from "../../images/dao/revolving_point.gif";
import buyToken from "../../images/buttons/buy_token_button_without_hover.png";
import buyTokenHover from "../../images/buttons/buy_token_button_with_hover.png";
import proposal from "../../images/buttons/voting_button_un_hover_copy.png";
import proposalHover from "../../images/buttons/voting_proposal_button_hover.png";
function Dao() {
  const [buyTokenButtonHover, setBuyTokenButtonHover] = useState(false);
  const [proposalButtonHover, setProposalButtonHover] = useState(false);
  function onHover(button) {
    if (button === "token") {
      setBuyTokenButtonHover(true);
    }
    if (button === "proposal") {
      setProposalButtonHover(true);
    }
  }
  function unHover(button) {
    if (button === "token") {
      setBuyTokenButtonHover(false);
    }
    if (button === "proposal") {
      setProposalButtonHover(false);
    }
  }

  return (
    <div id="mainDaoId" className="mainDaoDiv">
      <section className="daoSection1">
        <img className="daoImg1" src={daoParrot} alt="parrot" />
        <img className="daoImg2 rotateDao" src={daoImgRotate} alt="rotation" />
      </section>
      <section className="daoSection2">
        <h2>DECENTRALIZED AUTONOMOUS ORGANIZATION</h2>
        <p>
          CONTROL YOUR WORLD IN METAVERSE SPACE, BY OWNING A PART OF BIRDSLAND
          SHARE YOUR EXPERIENCE, SHARE YOUR PROPOSALS AND BE A PART OF NEW
          GENERATION, WHERE YOU ARE THE OWNER OF YOUR META-LIFE,
        </p>
        <div className="buttonDiv">
          <img
            onMouseOver={() => onHover("token")}
            onMouseLeave={() => unHover("token")}
            src={buyTokenButtonHover ? buyTokenHover : buyToken}
            alt="buy Token"
          />
          <img
            onMouseOver={() => onHover("proposal")}
            onMouseLeave={() => unHover("proposal")}
            src={proposalButtonHover ? proposalHover : proposal}
            alt="proposal"
          />
        </div>
      </section>
    </div>
  );
}

export default Dao;
