import "./header.css";
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CardMedia from '@mui/material/CardMedia';
import logo from "../../images/logo/3d-logo-MadeByAmna.png";
import DownloadHover from "../../images/buttons/donload_hover.png";
import DownloadunHover from "../../images/buttons/download_unhover.png";
import { useNavigate } from "react-router-dom";
import axios from "axios"
import fileDownload from "js-file-download"
 
function Header() {
let navigate = useNavigate();

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#85c800',
  boxShadow: 24,
  p: 4,
 
  
};


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [hoverLetsGoButton, setHoverLetsGoButton] = React.useState(false);

  function hoverAndUnHoverButton(type) {
    if (type === "hover") {
      setHoverLetsGoButton(true);
    } else if (type === "unHover") {
      setHoverLetsGoButton(false);
    }
  }

  function scrool(id) {
    const elem = document.getElementById(id);
    elem.scrollIntoView({ behavior: "smooth" });
  }
  function handleDownload (url, filename)  {
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
  }
  return (
    <div className="mainHeaderDiv">
      <div className="logoDiv">
        <img src={logo} alt="logo"
          onClick={() => {
            window.location.reload()
          }} />
      </div>

      <div className="headerTabs">
        <p onClick={handleOpen}  >GAME</p>
        <p
          onClick={() => {
            scrool("mainDaoId");
          }}
        >
          DAO
        </p>
        <p
          onClick={() => {
            scrool("MainMarketId");
          }}
        >
          MARKETPLACE
        </p>
        <p onClick={()=>{
          navigate("../faq");
        }}>FAQ</p>
        <p
          onClick={() => {
            scrool("mainRoadMapId");
          }}
        >
          ROADMAP
        </p>
        <p
          onClick={() => {
            scrool("mainTeamId");
          }}
        >
          TEAM
        </p>
        <p
          onClick={() => { window.open("https://birdz.land/BirdzLand-Whitepaper.pdf", "_blank") }}
        >WHITEPAPER</p>
      </div>

      <div className="backGroundGradiant">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          
        >
          <Box sx={style}
          style={{background: 'linear-gradient(to right, #24243e, #302b63, #0f0c29)', borderRadius:"40px" ,color:"white",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems:"center"
          
        }}
          
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              BirdzLand Game Desktop version
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              It’s time to get your hands on this year’s favorite game for Bird Lovers with a whole new concept of Metaverse.

              Explore your hearts out with breathtaking visuals and gameplay starting now we have released an Alpha  demo that comprises of exotic birds and landscape where you can interact with the Birdz and different environments.
            </Typography>
            
            <CardMedia
              component="img"
              image={hoverLetsGoButton ? DownloadHover : DownloadunHover}
              title={"Download Game"}
              onClick={() => handleDownload('https://birdz.land/BirdzLand_Sample.exe', 'BirdzLand_Sample.exe') }
              onMouseOver={() => hoverAndUnHoverButton("hover")}
              onMouseLeave={() => hoverAndUnHoverButton("unHover")}
              style={{width:"45%" ,marginTop:"5%",
              transition: "all 1s ease-in-out",
              '&:hover': { transform: "scale(1.3)" }}}
            />
          </Box>
        </Modal>
      </div>

    </div>
  );
        }

export default Header;
