import React, { useEffect, useState } from "react";
import "./faq.css";
import backGround from "../../images/faq-images/faq-background-Img.png";
import faqLogo from "../../images/faq-images/faq-logo.png";
import flyingBird1 from "../../images/faq-images/flying-bird1.png";
import flyingBird2 from "../../images/faq-images/flying-bird2.png";
import stand1 from "../../images/faq-images/stand1.png";
import stand2 from "../../images/faq-images/stand2.png";

import woodenQue from "../../images/faq-images/wooden-with-que.png";
import grassAns from "../../images/faq-images/grass-with-answer.png";
import arrowUP from "../../images/faq-images/arrow-up.png";
import arrowDown from "../../images/faq-images/arrow-down.png";
import footerBird from "../../images/faq-images/footerBird_in_FAQ.png";

function Faq() {
 
  const [firstArrowState, setFirstArrowState] = useState(false);
  const [secondArrowState, setSecondArrowState] = useState(false);
  const [thirdArrowState, setThirdArrowState] = useState(false);
  const [fourthArrowState, setFourthArrowState] = useState(false);
  const [fifthArrowState, setFifthArrowState] = useState(false);

  function hideAnswer(answer) {
    
    if (answer === "first") {
      setFirstArrowState(!firstArrowState);

      // setSecondArrowState(false);
      // setThirdArrowState(false);
      // setFourthArrowState(false);
    }
    if (answer === "second") {
      setSecondArrowState(!secondArrowState);
      // setFirstArrowState(false);
      // setThirdArrowState(false);
      // setFourthArrowState(false);
    }
    if (answer === "third") {
      setThirdArrowState(!thirdArrowState);
      // setSecondArrowState(false);
      // setFirstArrowState(false);
      // setFourthArrowState(false);
    }
    if (answer === "fourth") {
      setFourthArrowState(!fourthArrowState);
      // setThirdArrowState(false);
      // setSecondArrowState(false);
      // setFirstArrowState(false);
    }
    if (answer === "fifth") {
      setFifthArrowState(!fifthArrowState)
    }
  }
  return (
    <div
      style={{ backgroundImage: `url(${backGround})` }}
      className="mainFaqDiv"
    >
      <div className="FaqSession1">
        <img className="faqFlyingBird1" src={flyingBird1} />
        <img className="faqLogo" src={faqLogo} />
        <img className="faqFlyingBird2" src={flyingBird2} />
      </div>
      <div className="FaqSession2">
        <div className="standWoodDiv">
          <img src={stand1} className="standWoodImg1" />
          <img src={stand2} className="standWoodImg2" />
        </div>
        <div className="queAnsDiv">
          <div className="firstQueAns">
            <div className="queDivOfFirstQueAns">
              <img src={woodenQue} className="woodenQueImg" />
              <div
                className={
                   firstArrowState === false
                    ? "marginBottom QueAndArrowIconDiv"
                    : "QueAndArrowIconDiv"
                }
              >
                <h3 style={{ margin: "0px" }}>What is Birdz?</h3>
                <img
                  src={
                      firstArrowState === false
                      ? arrowUP
                      : arrowDown
                  }
                  className="arrowUpAndDownImg"
                  onClick={() => {
                    hideAnswer("first");
                  }}
                />
              </div>
            </div>
            <div
              className={
                 firstArrowState === false
                  ? "displayNone"
                  : "ansDivFirstQueAns"
              }
            >
              <img src={grassAns} />
              <h4>
              Birdz is a meta-verse game developed for all audiences able to connect and collect different Birdz ranging from lovebirds to macaws.
              </h4>
            </div>
          </div>

          <div className="secondQueAns">
            <div className="queDivOfFirstQueAns">
              <img src={woodenQue} className="woodenQueImg" />
              <div
                className={
                   secondArrowState === false
                    ? "marginBottom QueAndArrowIconDiv"
                    : "QueAndArrowIconDiv"
                }
              >
                <h3 style={{ margin: "0px" }}>What is a Birdz NFT?</h3>
                <img
                  className="arrowUpAndDownImg"
                  src={
                     secondArrowState === false
                      ? arrowUP
                      : arrowDown
                  }
                  onClick={() => {
                    hideAnswer("second");
                  }}
                />
              </div>
              <div></div>
            </div>
            <div
              className={
                 secondArrowState === false
                  ? "displayNone"
                  : "ansDivFirstQueAns"
              }
            >
              <img src={grassAns} />
              <h4>
              Birdz are the residents that inhabit BirdzLand, and they're cute little creatures inspired by the natural beauty of our Birds. They need to be taken care of regularly in order for them to grow.
              </h4>
            </div>
          </div>
          <div className="thirdQueAns">
            <div className="queDivOfFirstQueAns">
              <img src={woodenQue} className="woodenQueImg" />
              <div
                className={
                  thirdArrowState === false
                    ? "marginBottom QueAndArrowIconDiv"
                    : "QueAndArrowIconDiv"
                }
              >
                <h3 style={{ margin: "0px" }}>How can I get a Birdz?</h3>
                <img
                  src={
                     thirdArrowState === false
                      ? arrowUP
                      : arrowDown
                  }
                  className="arrowUpAndDownImg"
                  onClick={() => {
                    hideAnswer("third");
                  }}
                />
              </div>
              <div></div>
            </div>
            <div
              className={
                 thirdArrowState === false
                  ? "displayNone"
                  : "ansDivFirstQueAns"
              }
            >
              <img src={grassAns} />
              <h4>
              Birdz tokens (and other Birdz items) can currently be purchased from the Birdz marketplace
              </h4>
            </div>
          </div>
          <div className="fourthQueAns">
            <div className="queDivOfFirstQueAns">
              <img src={woodenQue} className="woodenQueImg" />
              <div
                className={
                   fourthArrowState === false
                    ? "marginBottom QueAndArrowIconDiv"
                    : "QueAndArrowIconDiv"
                }
              >
                <h3 style={{ margin: "0px" }}>Birdz Editions?</h3>
                <img
                  src={
                     fourthArrowState === false
                      ? arrowUP
                      : arrowDown
                  }
                  className="arrowUpAndDownImg"
                  onClick={() => {
                    hideAnswer("fourth");
                  }}
                />
              </div>
              <div></div>
            </div>
            <div
              className={
                fourthArrowState === false
                  ? "displayNone"
                  : "ansDivFirstQueAns"
              }
            >
              <img src={grassAns} />
              <h4>
              Each Birdz NFT belongs to an edition that indicates its issue time. For example, all Birdz NFT pre-sale in the late will be of 2022 Edition.
              </h4>
            </div>
          </div>

          <div className="fifthQueAns">
            <div className="queDivOfFirstQueAns">
              <img src={woodenQue} className="woodenQueImg" />
              <div
                className={
                   fifthArrowState === false
                    ? "marginBottom QueAndArrowIconDiv"
                    : "QueAndArrowIconDiv"
                }
              >
                <h3 style={{ margin: "0px" }}>When was Birdz founded?</h3>
                <img
                  src={
                     fifthArrowState === false
                      ? arrowUP
                      : arrowDown
                  }
                  className="arrowUpAndDownImg"
                  onClick={() => {
                    hideAnswer("fifth");
                  }}
                />
              </div>
              <div></div>
            </div>
            <div
              className={
                 fifthArrowState === false
                  ? "displayNone"
                  : "ansDivFirstQueAns"
              }
            >
              <img src={grassAns} />
              <h4>
              Birdzland was founded in 2022 in January by crypto enthusiast, blockchain experts
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="FaqSession3">
        <img src={footerBird} />
      </div>
    </div>
  );
}

export default Faq;
