import { useState } from "react";
import "./marketPlaceComp.css";
import marketPlace from "../../images/marketPlace/marketPlaceDoodle.png";
import img1 from "../../images/marketPlace/Group6.png";
import img2 from "../../images/birds/bird2.gif";
import button from "../../images/buttons/lets_go_button_hover.png";
import button2 from "../../images/buttons/lets_go_unhover.png";
function MarketPlaceComp() {
  const [hoverLetsGoButton, setHoverLetsGoButton] = useState(false);
  function hoverAndUnHoverButton(type) {
    if (type === "hover") {
      setHoverLetsGoButton(true);
    } else if (type === "unHover") {
      setHoverLetsGoButton(false);
    }
  }
  return (
    <div id="MainMarketId" className="mainMarketPlaceDiv">
      <section className="marketPlaceSession1">
        <div
          className="marketPlaceBackground"
          style={{
            backgroundImage: `url(${marketPlace})`,
          }}
        >
          <h3>THE BIRDSLAND</h3>
          <h3>MARKETPLACE</h3>
          <p>
            Hello you are in the birdsland right now hope you will enjoy the
            experience
          </p>
          <h4>so, step into the world of birds</h4>
        </div>
        
        <img
          onMouseOver={() => hoverAndUnHoverButton("hover")}
          onMouseLeave={() => hoverAndUnHoverButton("unHover")}
          src={hoverLetsGoButton ? button : button2}
          
          onClick={()=>{  window.open("https://marketplace.birdz.land/", "_blank")}}
        />
      </section>
      <session className="marketPlaceSession2">
        <img className="img1 " src={img2} />
        <img className="img2" src={img1} />
      </session>
    </div>
  );
}

export default MarketPlaceComp;
