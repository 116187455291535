import React, { useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import MainComp from "./mainComponent";
import Faq from "./components/FAQ-COMP/faq";
function App() {
  useEffect(() => {}, []);
  return (
    <Routes>
      <Route path="/" element={<MainComp/>}/>
      <Route path="/faq" element={<Faq/>}/>
    </Routes>
  );
}

export default App;
