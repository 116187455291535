import { useState } from "react";
import "./realityComp.css";
import bird1 from "../../images/birds/Layer_13.gif";

import bird2 from "../../images/birds/birds.gif";

import flyingLand from "../../images/lands/flying_land.png";

import demo_Hover from "../../images/buttons/demo_hover.png";
import demo_unhover from "../../images/buttons/demo_unhover.png";
import CloudComp from "./cloudComp";
function RealityComp() {
  const [hoverOndemoButton, setHoverOnDemoButton] = useState(false);
  function onHoverAndUnHover(type) {
    if (type === "hover") {
      setHoverOnDemoButton(true);
    }
    if (type === "unHover") {
      setHoverOnDemoButton(false);
    }
  }

  return (
    <div className="mainRealityDiv">
      <CloudComp />

      <section className="realitySession1">
       
        <img
          className="flyingBird1"
          src={bird1}
          alt="bird"
          style={{ width: "24%", marginLeft: "0%", marginBottom: "2%" }}
        />
      
        <h3>MAKE YOUR REALITY</h3>
        <h3>OWN YOUR REALITY</h3>
        <p>
          Birdsland is the game for everyone where you can be a part of
          metaverse and its breath taking landscape and characters
        </p>
        <p style={{ margin: "0px" }}>
          It is embedded with all new features and art styles
        </p>
        <div className="demoButtonDiv">
          <img
            onMouseOver={() => {
              onHoverAndUnHover("hover");
            }}
            onMouseLeave={() => {
              onHoverAndUnHover("unHover");
              
            }}
            onClick={()=>{  window.open("https://demo.birdz.land/", "_blank")}}
            className="demo-Button"
            src={hoverOndemoButton ? demo_Hover : demo_unhover}
            alt="demo-button"
          />
        </div>
      </section>
      <session className="realitySession2">
        <img
          src={bird2}
          alt="bird"
          style={{
            width: "20%",
            position: "relative",
            left: "54%",
            top: "20%",
            zIndex: 1,
          }}
        />
        {/* <img src={bird3} alt="bird" style={{ width: "9%", position: "relative", left: "77%"   }} /> */}
        <img
          className="flyingland"
          src={flyingLand}
          alt="land"
          style={{ width: "67%", position: "relative", left: "25%" }}
        />
      </session>
    </div>
  );
}

export default RealityComp;
