import React, { useEffect, useState } from "react";
import "./footer.css";
import member1 from "../../images/teamMembers/1.png";
import member4 from "../../images/teamMembers/2.png";
import member3 from "../../images/teamMembers/3.png";
import member2 from "../../images/teamMembers/4.png";
import roadMap from "../../images/roadMap/roadmap.png";
import twneon from "../../images/footer/Twitter.png";
import lnkneon from "../../images/footer/linkedin.png";
import grassFooter from "../../images/footer/Grass_Footer.png";
import discordLogo from "../../images/socialMediaIcons/socialMediaDiscordLogo.png";
import linkedInLogo from "../../images/socialMediaIcons/socialMediaLinkedInLogo.png";
import fbLogo from "../../images/socialMediaIcons/socialMediaFaceBookLogo.png";
import instaLogo from "../../images/socialMediaIcons/socialMediaInstaLogo.png";
import telegramLogo from "../../images/socialMediaIcons/socialMediaTelegramLogo.png";
import twitterLogo from "../../images/socialMediaIcons/twitter@4x.png";

function Footer() {
  const [hoverImage, setHoverImage] = useState("");
  const [name, setName] = useState("");
  const [designation, setdesignation] = useState("");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [twitterUrl, settwitterUrl] = useState("");

  function updateBio(name, desigation, linkedInId, twitterUrl) {
    if (name === "DOA") {
      document.getElementById("introDivId").style.left = "47%";
    } else if (name === "ZOHAIB KHALID") {
      document.getElementById("introDivId").style.left = "54%";
    } else if (name === "HARRIS") {
      document.getElementById("introDivId").style.left = "60%";
    } else if (name === "AMMAD") {
      document.getElementById("introDivId").style.left = "66%";
    }
    setName(name);
    setdesignation(desigation);
    setLinkedInUrl(linkedInId);
    settwitterUrl(twitterUrl);
  }
  return (
    <div className="mainFooterDiv">
      <div id="mainRoadMapId" className="roadmapImgDiv">
        <img src={roadMap} alt="roadmap" />
      </div>
      <div id="introDivId" className={hoverImage ? hoverImage : "displayNone"}>
        <h4>{name}</h4>
        <p>{designation}</p>
        {/* <div className="neonIcon">
          <img src={twneon} alt="LinkedIn"  onClick={() => { window.open({twitterUrl}, "_blank") }}/>
          <img src={lnkneon} alt="twitterUrl"  onClick={() => { window.open({linkedInUrl}, "_blank") }}/>
        </div> */}
      </div>
      <div id="mainTeamId" className="teamDiv">
        <img
          onMouseOver={() => {
            setHoverImage("introDivDOA");
            updateBio("DOA", "Graphics Manager", "ABC.com", "twitterUrl");
          }}
          onMouseLeave={() => {
            setHoverImage("");
            updateBio("", "", "", "");
          }}
          src={member1}
        />
        <img
          onMouseOver={() => {
            setHoverImage("introDivZHB");
            updateBio("ZOHAIB KHALID", "CEO", "ABC.com", "twitterUrl");
          }}
          onMouseLeave={() => {
            setHoverImage("");
            updateBio("", "", "", "");
          }}
          src={member2}
        />
        <img
          onMouseOver={() => {
            setHoverImage("introDivHRS");
            updateBio("HARRIS", "CTO", "ABC.com", "twitterUrl");
          }}
          onMouseLeave={() => {
            setHoverImage("");
            updateBio("", "", "", "");
          }}
          src={member3}
        />
        <img
          onMouseOver={() => {
            setHoverImage("introDivAMD");
            updateBio(
              "AMMAD",
              "Game Development Lead",
              "ABC.com",
              "twitterUrl"
            );
          }}
          onMouseLeave={() => {
            setHoverImage("");
            updateBio("", "", "", "");
          }}
          src={member4}
        />
      </div>
      <div className="footerGrass">
        <img src={grassFooter} />
      </div>
      <div className="socialMedia">

        <section className="socialMediaSection1">
          {/* <h3>SOCIAL MEDIA</h3> */}
          <div className="innerSocialMediaImgDiv">
            <img className="glowing-circle-linkedin" src={linkedInLogo} onClick={() => { window.open("https://www.linkedin.com/company/birdzland/about/", "_blank") }} />
            <img className="glowing-circle-Facebook" src={fbLogo} onClick={() => { window.open("https://www.facebook.com/BirdzLand-101669339273759", "_blank") }} />
            <img className="glowing-circle-Instagram" src={instaLogo} onClick={() => { window.open("https://www.instagram.com/birdz_land/", "_blank") }} />
            <img className="glowing-circle-Telegram" src={telegramLogo} onClick={() => { window.open("https://twitter.com/Birdz_Land", "_blank") }} />
            <img className="glowing-circle-Twitter" src={twitterLogo} onClick={() => { window.open("https://twitter.com/Birdz_Land", "_blank") }} />
            <img className="glowing-circle-Discord" src={discordLogo} onClick={() => { window.open("https://discord.com/invite/cqSBWeH5", "_blank") }} />
          </div>
        </section>
        <section className="socialMediaSection2">
          <h5>ALL RIGHTS RESERVED &copy; 2022.</h5>
          <h6>BIRDZLAND FOUNDATION</h6>
        </section>
        <section className="socialMediaSection3">
          <h3>CONTACT US</h3>
          <h5>INFO@BIRDZ.LAND</h5>
        </section>
      </div>
    </div>
  );
}

export default Footer;
